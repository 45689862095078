.mobile-slick-device .slick-track {
  display: flex;
  align-items: center;
}

.mobile-slick-device .slick-center img {
  width: 125px;
  height: 108px;
}

.mobile-slick-device img {
  width: 68px;
  height: 55px;
}

.mobile-slick-device .slick-list .slick-track .slick-current svg {
  display: block;
}

.mobile-slick-device .slick-list .slick-track h3 {
  display: none;
}

.mobile-slick-device .slick-list .slick-track .device-btn {
  display: none;
}

.mobile-slick-device .slick-list .slick-track .slick-current h3 {
  display: block;
}

.mobile-slick-device .slick-list .slick-track .slick-current .device-btn {
  display: block;
}

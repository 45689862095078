@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@font-face {
  font-family: "NEXON Lv2 Gothic OTF";
  font-style: normal;
  font-weight: normal;
  src: url(../public/font/nexon/NEXON-Lv2-Gothic.woff) format("woff");
  unicode-range: U+AC00-D7A3;
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: normal;
  src: url(../public/font/poppins/Poppins-Regular.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 300;
  src: url(../public/font/poppins/Poppins-Light.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 500;
  src: url(../public/font/poppins/Poppins-Medium.woff) format("woff");
  font-display: swap;
}

@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  src: url(../public/font/poppins/Poppins-SemiBold.woff) format("woff");
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "poppins";
  width: 100vw;
  height: 100vh;
  line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #2d4b9e transparent;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: #2d4b9e;
}

::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}

*:focus {
  outline: none;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  padding: 0;
  cursor: pointer;
}

.slick-dots .slick-active button {
  width: 44px;
  height: 9px;
  background-color: black;
  border-radius: 9px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .slick-dots .slick-active button {
    width: 30px;
    height: 6px;
  }
}

.mobile-slick-dots .slick-active button {
  background-color: #a6d9ff;
}

.toaster-wrapper > div {
  display: none !important;
}

.toaster-wrapper > div:first-child {
  display: flex !important;
}

.scrollbar-hidden {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display:none;
}
